import Filter from "../../../components/Filter";
const filterDefinition = {
    category: "exactString",
    search: "multiString",
};
const VehicleReducer = (state, action) => {
    const { type, payload } = action;

    let sorted;

    switch (type) {
        case "SELECTED_VEHICLE":
            return { ...state, selectedVehicle: payload, vehicleId: payload };
        case "SELECTED_TIMEFRAME":
            return { ...state, selectedTimeframe: payload };
        case "SELECTED_DRIVER":
            return { ...state, selectedDriver: payload };
        case "SELECTED_CATEGORY":
            return { ...state, selectedCategory: payload };
        case "SET_LABEL":
            return { ...state, label: payload };
        case "RESET_FILETER":
            return { ...state, label: '', selectedCategory: 'all', selectedDriver: 'all',  selectedVehicle: { name: "", value: 'all'}, selectedImageArr: [], selectedTimeframe:false, vehicleId: "all" };
        case "RESET_SELECTED_IMAGE":
            return { ...state, selectedImageArr: [], selectedItem: null };
        case "CHANGE_SELECTED_IMAGE":
            let selectedImageArr = state.selectedImageArr;
            const Index = selectedImageArr.indexOf(payload.id);
            if (Index > -1) {
                    selectedImageArr.splice(Index, 1);
                } else {
                    selectedImageArr.push(payload.id);
                }
            return { ...state, selectedImageArr: selectedImageArr };
        case "VEHICLES_OPTS":
            const driversOpts = payload.data.drivers.driver.sort((a, b) => (a.name > b.name) - (a.name < b.name)).map((v) => {
                return { name: v.name, value: v.userId };
            });
            driversOpts.unshift({ name: "All", value: 'all' });
            const categoryOpts = payload.data.category.sort((a, b) => (a.name > b.name) - (a.name < b.name)).map((c) => {
                return { name: c.name, value: c.id };
            });
            categoryOpts.unshift({ name: "All", value: 'all' });
            let selectedVehicle = state.selectedVehicle

            const vehiclesOpts = payload.data.Vehicle.sort((a, b) => (a.vehicleId > b.vehicleId) - (a.vehicleId < b.vehicleId)).map((v) => {
                if(state.selectedVehicle == v.id){
                    selectedVehicle = { name: v.vehicleId, value: v.id };
                }
                return { name: v.vehicleId, value: v.id };
            });
            vehiclesOpts.unshift({ name: "All", value: 'all' });

            return { ...state, driversOpts: driversOpts, vehiclesOpts: vehiclesOpts, categoryOpts:categoryOpts, selectedVehicle: selectedVehicle };
        case "ADD":
            state.page = payload.page;
            state.totalPages = payload.totalPages;

            if (payload.page == 1) {
                state.list = payload.data;
            } else {
                state.list = [...state.list, ...payload.data];
            }

            state.tempList = [...state.tempList, ...payload.data];
            const uniqueImage = [...new Map(state.tempList.map((item) => [item["id"], item])).values()];
            state.tempList = uniqueImage;

            return { ...state, vehicleImages: payload.data, isLoading: false, vehicleId: state.vehicleId || "" };

        case "REMOVE":
            state.tempList = state.tempList.filter((item) => item.id !== payload.id);
            return {
                ...state,
                vehicleImages: state.vehicleImages.filter((item) => item.id !== payload.id),
                list: state.list.filter((item) => item.id !== payload.id),
            };
        case "SEARCH":
            const uniqueImages = [...new Map(state.tempList.map((item) => [item["id"], item])).values()];
            sorted = sortBy(uniqueImages, state.sortBy);
            const filtered = new Filter(filterDefinition, payload, sorted);

            return { ...state, list: filtered, filter: payload, isLoading: false };
        case "ADD_CATEGORY":
            return { ...state, vehicleImageCategory: payload, isLoading: false };
        case "ADD_VEHICLEID":
            return { ...state, vehicleId: payload, selectedVehicle: (payload)?{ name: payload.vehicleId, value: payload.id }:{}};
        case "EDIT":
            state.vehicleImages[payload.key] = payload.vehicleImage;

            return { ...state };
        case "LOADING":
            return { ...state, isLoading: payload.status };
        case "LOADING_WRAPPER":
            return { ...state, isLoadingWrapper: payload.status };
        case "SORTING":
            state.sortBy = [payload];
            sorted = sortBy(state.vehicleImages, state.sortBy);
            const sortingFiltered = new Filter(filterDefinition, state.filter, sorted);

            return { ...state, list: sortingFiltered, sortBy: [payload], isLoading: false };
        case 'SET_SELECTED_IMAGE':
            return {
                ...state,
                selectedImageArr: action.payload,
                selectedItem: action.payload.length === 0 ? null : state.selectedItem
            };
        case 'SET_SELECTED_ITEM':
            return {
                ...state,
                selectedItem: action.payload
            };
        default:
            return state;
    }
};

const sortBy = (vehicleImages, criterias) => {
    criterias.reverse().map((c) => {
        let d = c.direction;

        vehicleImages.sort((f, s) => {
            switch (c.field) {
                case "createdAt":
                    if (f.createdAt && s.createdAt && f.createdAt.timestamp && s.createdAt.timestamp) {
                        return d === 0
                            ? f.createdAt.timestamp - s.createdAt.timestamp
                            : s.createdAt.timestamp - f.createdAt.timestamp;
                    } else {
                        return false;
                    }
                default:
                    if (d === 0) {
                        if (f[c.field] && s[c.field])
                            return f[c.field]
                                .toString()
                                .localeCompare(s[c.field].toString(), undefined, { numeric: true });
                        else return undefined;
                    } else {
                        if (f[c.field] && s[c.field])
                            return s[c.field]
                                .toString()
                                .localeCompare(f[c.field].toString(), undefined, { numeric: true });
                        else return undefined;
                    }
            }
        });
    });

    return vehicleImages;
};

export default VehicleReducer;
