import {getMainStation} from "../Utilities";
import {parseToken} from "../domain/security/state/Token";

class AccessCheck {
    static features = Object.freeze({
        SYS_ADMIN           : 'SYS_ADMIN',

        DASHBOARD           : 'DASHBOARD',

        SCHEDULER           : 'SCHEDULER',
        SCHEDULER_PAST      : 'SCHEDULER_PAST',
        SCHEDULER_FUTURE    : 'SCHEDULER_FUTURE',

        ROUTE_COMMITMENT    : 'ROUTE_COMMITMENT',
        SCHEDULE_BALANCE    : 'SCHEDULE_BALANCE',
        EMPLOYEE_BALANCE    : 'EMPLOYEE_BALANCE',

        INCIDENTS           : 'INCIDENTS',
        INC_ACC             : 'INCIDENTS_ACCIDENT_INJURY',
        INC_NCN             : 'INCIDENTS_NCNS',
        INC_LAT             : 'INCIDENTS_LATE',
        INC_ROA             : 'INCIDENTS_ROADSIDE',
        INC_RES             : 'INCIDENTS_RESIGNATION',
        INC_DIS             : 'INCIDENTS_DISRESPECT',
        INC_UPD             : 'INCIDENTS_UDP',
        INC_DRO             : 'INCIDENTS_DROP_ROUTE',
        INC_ABO             : 'INCIDENTS_ABONDONED_ROUTE',
        INC_THE             : 'INCIDENTS_THIEF',
        INC_CUS             : 'INCIDENTS_CUSTOMER_COMPLAIN',
        INC_INC             : 'INCIDENTS_INCOMPLETE_ROUTE',
        INC_DEV             : 'INCIDENTS_DEVICE_DAMAGE',

        SCORECARD           : 'SCORECARD',
        VAN_REPORT          : 'VAN_REPORT',

        COMPANY_MANAGEMENT  : 'COMPANY_MANAGEMENT',
        CM_STATIONS         : 'COMPANY_MANAGEMENT_STATIONS',
        CM_E_RESET_PASSWORD : 'COMPANY_MANAGEMENT_EMPLOYEES_RESET_PASSWORD',
        CM_SKILL            : 'COMPANY_MANAGEMENT_SKILL',
        CM_EMPLOYEE         : 'COMPANY_MANAGEMENT_EMPLOYEE',
        CM_E_PERMISSION_CHANGE: 'COMPANY_MANAGEMENT_EMPLOYEE_PERMISSION_CHANGE',
        
        ST_SKILL_RATES      : 'STATION_SKILL_RATES',
        ST_RATE_CARD        : 'STATION_RATE_CARD',
        ST_SHIFT_TYPES      : 'STATION_SHIFT_TYPES',
        ST_INVOICE_TYPES    : 'STATION_INVOICE_TYPES',
        ST_SCHEDULES        : 'STATION_SCHEDULES',
        
        ANNOUNCEMENTS       : 'ANNOUNCEMENTS',

        SETTINGS            : 'SETTINGS',
        PAYCOM              : 'PAYCOM',
        STATION_NARRATIVE   : 'STATION_NARRATIVE',
    })

    static addons = Object.freeze({
        AUTOMATED_COACHING: 'AUTOMATED_COACHING',
        VEHICLE_MAINTENANCE_PLAN: 'VEHICLE_MAINTENANCE_PLAN',
        VEHICLE_AI_PHOTO_INSPECTION: 'VEHICLE_AI_PHOTO_INSPECTION',
    });

    static OPS = Object.freeze({
        C : 'CREATE',
        R : 'READ',
        U : 'UPDATE',
        D : 'DELETE'
    })

    static SCOPE = Object.freeze({
        COMPANY: 'COMPANY',
        SAME_STATION: 'SAME_STATION'
    })

    static #config = [
        // plans
        {
            features: [
                ...Object.values(this.features)
            ],
            plans: [
                'partner'
            ]
        },
        {
            features: [
                this.features.SCHEDULER,
                this.features.SCHEDULER_PAST,
                this.features.SCHEDULER_FUTURE,
                this.features.COMPANY_MANAGEMENT,
                this.features.CM_STATIONS,
                this.features.CM_E_RESET_PASSWORD,
                this.features.CM_SKILL,
                this.features.CM_EMPLOYEE,
                this.features.CM_E_PERMISSION_CHANGE,
                this.features.ST_SKILL_RATES,
                this.features.ST_RATE_CARD,
                this.features.ST_SHIFT_TYPES,
                this.features.ST_INVOICE_TYPES,
                this.features.ST_SCHEDULES,
                this.features.SETTINGS,
            ],
            plans: [
                'basic'
            ],
        },

        // roles
        {
            features: [
                ...Object.values(this.features),
                ...Object.values(this.addons)
            ],
            roles: [
                'ROLE_OWNER',
                'ROLE_OPERATIONS_ACCOUNT_MANAGER',
                // 'ROLE_OPERATIONS_MANAGER',
                'ROLE_CENTRAL_DISPATCHER',
                // 'ROLE_DISPATCHER',
                'ROLE_DISPATCHER_WITH_NO_OPEN_SHIFT_FEATURE'
            ],
            ops: [
                this.OPS.C,
                this.OPS.R,
                this.OPS.U,
                this.OPS.D,
            ],
            scope: [
                this.SCOPE.COMPANY
            ]
        },
        {
            features: [
                this.features.DASHBOARD,
                this.features.SCHEDULER,
                this.features.SCHEDULER_PAST,
                this.features.SCHEDULER_FUTURE,
                this.features.COMPANY_MANAGEMENT,
                this.features.CM_STATIONS,
                this.features.CM_E_RESET_PASSWORD,
                this.features.CM_SKILL,
                this.features.CM_EMPLOYEE,
                this.features.CM_E_PERMISSION_CHANGE,
                this.features.ST_SKILL_RATES,
                this.features.ST_RATE_CARD,
                this.features.ST_SHIFT_TYPES,
                this.features.ST_INVOICE_TYPES,
                this.features.ST_SCHEDULES,
                this.features.ROUTE_COMMITMENT,
                this.features.SCHEDULE_BALANCE,
                this.features.EMPLOYEE_BALANCE,
                this.features.INCIDENTS,
                this.features.SCORECARD,
                this.features.SETTINGS
            ],
            roles: [
                'ROLE_OPERATIONS_MANAGER'
            ],
            ops: [
                this.OPS.C,
                this.OPS.R,
                this.OPS.U,
                this.OPS.D,
            ],
            scope: [
                this.SCOPE.COMPANY
            ]
        },
        {
            features: [
                this.features.SETTINGS
            ],
            roles: [
                'ROLE_OPERATIONS_MANAGER'
            ],
            ops: [
                this.OPS.R,
            ],
            scope: [
                this.SCOPE.COMPANY
            ]
        },
        {
            features: [
                this.features.SETTINGS,
                this.features.ST_RATE_CARD,
                this.features.ST_INVOICE_TYPES,
            ],
            roles: [
                'ROLE_CENTRAL_DISPATCHER'
            ],
            ops: [
            ],
            scope: [
                this.SCOPE.COMPANY
            ]
        },
        {
            features: [
                this.features.ST_SKILL_RATES
            ],
            roles: [
                'ROLE_CENTRAL_DISPATCHER'
            ],
            ops: [
                this.OPS.R
            ],
            scope: [
                this.SCOPE.COMPANY
            ]
        },
        {
            features: [
                this.features.ST_SKILL_RATES,
                this.features.ST_RATE_CARD,
                this.features.ST_INVOICE_TYPES,
            ],
            roles: [
                'ROLE_DISPATCHER'
            ],
            ops: [
            ],
            scope: [
                this.SCOPE.SAME_STATION
            ]
        },
        {
            features: [
                this.features.DASHBOARD,
                this.features.SCHEDULER,
                this.features.SCHEDULER_PAST,
                this.features.SCHEDULER_FUTURE,
                this.features.COMPANY_MANAGEMENT,
                this.features.INCIDENTS,
                this.features.CM_STATIONS,
                this.features.CM_E_RESET_PASSWORD,
                this.features.CM_SKILL,
                this.features.CM_EMPLOYEE,
                this.features.ST_SHIFT_TYPES,
                this.features.ST_SCHEDULES,
                this.features.SCHEDULE_BALANCE,
                this.features.EMPLOYEE_BALANCE,
                this.features.ROUTE_COMMITMENT
            ],
            roles: [
                'ROLE_DISPATCHER'
            ],
            ops: [
                this.OPS.R,
                this.OPS.U
            ],
            scope: [
                this.SCOPE.SAME_STATION
            ]
        },
        {
            features: [
                this.features.DASHBOARD,
                this.features.SCHEDULER,
                this.features.SCHEDULER_PAST,
                this.features.SCHEDULER_FUTURE,
                this.features.SCHEDULE_BALANCE,
                this.features.EMPLOYEE_BALANCE,
                this.features.INCIDENTS,
                this.features.COMPANY_MANAGEMENT,
                this.features.CM_STATIONS,
                this.features.CM_SKILL,
                this.features.CM_EMPLOYEE,
                this.features.CM_E_PERMISSION_CHANGE,
                this.features.CM_E_RESET_PASSWORD,
                this.features.ST_SCHEDULES,
                this.features.ST_SHIFT_TYPES,
                this.features.ST_SKILL_RATES,
                this.features.ST_RATE_CARD,
                this.features.ST_INVOICE_TYPES
            ],
            roles: [
                'ROLE_HR_ADMINISTRATOR'
            ],
            ops: [
                this.OPS.C,
                this.OPS.R,
                this.OPS.U,
                this.OPS.D,
            ],
            scope: [
                this.SCOPE.COMPANY
            ]
        },
        {
            features: [
                this.features.ST_RATE_CARD
            ],
            roles: [
                'ROLE_HR_ADMINISTRATOR'
            ],
            ops: [
                this.OPS.R,
            ],
            scope: [
                this.SCOPE.COMPANY,
            ]
        },
        {
            features: [
                this.features.DASHBOARD,
                this.features.SCHEDULER,
                this.features.SCHEDULER_PAST,
                this.features.SCHEDULE_BALANCE,
                this.features.INCIDENTS,
                this.features.COMPANY_MANAGEMENT,
                this.features.CM_STATIONS
            ],
            roles: [
                'ROLE_FLEET_MANAGER'
            ],
            ops: [
                this.OPS.C,
                this.OPS.R,
                this.OPS.U,
                this.OPS.D,
            ],
            scope: [
                this.SCOPE.COMPANY
            ]
        },
        {
            features: [
                this.features.CM_SKILL,
                this.features.CM_EMPLOYEE,
                this.features.ST_SHIFT_TYPES,
                this.features.ST_INVOICE_TYPES,
                this.features.ST_SCHEDULES,
            ], 
            roles: [
                'ROLE_FLEET_MANAGER'
            ],
            ops: [
                this.OPS.R
            ],
            scope: [
                this.SCOPE.COMPANY,
            ]
        },
        {
            features: [
                this.features.ROUTE_COMMITMENT
            ],
            roles: [
                'ROLE_FLEET_MANAGER'
            ],
            ops: [
                this.OPS.R,
                this.OPS.U
            ],
            scope: [
                this.SCOPE.COMPANY,
            ]
        },
        // {
        //     features: [
        //         this.features.DASHBOARD,
        //         this.features.SCHEDULE_BALANCE,
        //         this.features.INCIDENTS,
        //         this.features.COMPANY_MANAGEMENT,
        //         this.features.CM_STATIONS
        //     ],
        //     roles: [
        //         'ROLE_SAFETY_CHAMPION',
        //         'ROLE_SAFETY_MANAGER'
        //     ],
        //     ops: [
        //         this.OPS.C,
        //         this.OPS.R,
        //         this.OPS.U,
        //         this.OPS.D,
        //     ],
        //     scope: [
        //         this.SCOPE.COMPANY
        //     ]
        // },
        // {
        //     features: [
        //         this.features.CM_SKILL,
        //         this.features.CM_EMPLOYEE,
        //         this.features.ST_SHIFT_TYPES,
        //         this.features.ST_INVOICE_TYPES,
        //         this.features.ST_SCHEDULES,
        //         this.features.INCIDENTS
        //     ],
        //     roles: [
        //         'ROLE_SAFETY_CHAMPION',
        //         'ROLE_SAFETY_MANAGER'
        //     ],
        //     ops: [
        //         this.OPS.R,
        //     ],
        //     scope: [
        //         this.SCOPE.COMPANY,
        //     ]
        // },
        // {
        //     features: [
        //         this.features.SCHEDULER,
        //         this.features.SCHEDULER_PAST,
        //         this.features.SCHEDULER_FUTURE,
        //     ],
        //     roles: [
        //         'ROLE_SAFETY_CHAMPION',
        //         'ROLE_SAFETY_MANAGER'
        //     ],
        //     ops: [
        //         this.OPS.U
        //     ],
        //     scope: [
        //         this.SCOPE.COMPANY,
        //     ]
        // },
        {
            features: [
                this.features.DASHBOARD,
                this.features.SCHEDULER,
                this.features.SCHEDULER_PAST,
                this.features.SCHEDULER_FUTURE,
                this.features.ROUTE_COMMITMENT,
                this.features.SCHEDULE_BALANCE,
                this.features.EMPLOYEE_BALANCE,
                this.features.INCIDENTS,
                this.features.SCORECARD,
                this.features.COMPANY_MANAGEMENT,
                this.features.CM_STATIONS,
                this.features.CM_SKILL,
                this.features.CM_EMPLOYEE,
                this.features.CM_E_PERMISSION_CHANGE,
                this.features.CM_E_RESET_PASSWORD,
                this.features.ST_SKILL_RATES,
                this.features.ST_RATE_CARD,
                this.features.ST_SHIFT_TYPES,
                this.features.ST_INVOICE_TYPES,
                this.features.ST_SCHEDULES,
                this.features.ANNOUNCEMENTS,
                this.addons.VEHICLE_MAINTENANCE_PLAN,
                this.addons.VEHICLE_AI_PHOTO_INSPECTION,
            ],
            roles: [
                // 'ROLE_CENTRAL_DISPATCHER',
                'ROLE_STATION_MANAGER',
                'ROLE_ASSISTANT_STATION_MANAGER',
                // 'ROLE_DISPATCHER',
                // 'ROLE_LEAD_DRIVER',
                // 'ROLE_DELIVERY_ASSOCIATE',
                // 'ROLE_HR_ADMINISTRATOR',
                // 'ROLE_FLEET_MANAGER',
                // 'ROLE_SAFETY_MANAGER',
                // 'ROLE_SAFETY_CHAMPION',
            ],
            ops: [
                // this.OPS.C,
                this.OPS.R,
                this.OPS.U,
                // this.OPS.D,
            ],
            scope: [
                this.SCOPE.SAME_STATION
            ]
        },
        {
            features: [
                this.features.DASHBOARD,
            ],
            roles: [
                'ROLE_CHAT_USER',
            ],
            ops: [
                // this.OPS.C,
                this.OPS.R,
                this.OPS.U,
                // this.OPS.D,
            ],
            scope: [
                this.SCOPE.SAME_STATION
            ]
        },
        {
            features: [
                this.features.COMPANY_MANAGEMENT,
                this.features.CM_STATIONS,
                this.features.CM_E_RESET_PASSWORD,
                this.features.ST_SHIFT_TYPES,
                this.features.ST_SCHEDULES,
            ],
            roles: [
                // 'ROLE_CENTRAL_DISPATCHER',
                // 'ROLE_STATION_MANAGER',
                // 'ROLE_ASSISTANT_STATION_MANAGER',
                // 'ROLE_DISPATCHER',
                'ROLE_DISPATCHER_WITH_NO_OPEN_SHIFT_FEATURE',
                'ROLE_DISPATCHER_DAILY',
                // 'ROLE_LEAD_DRIVER',
                // 'ROLE_DELIVERY_ASSOCIATE',
                // 'ROLE_HR_ADMINISTRATOR',
                // 'ROLE_FLEET_MANAGER',
                // 'ROLE_SAFETY_MANAGER',
                // 'ROLE_SAFETY_CHAMPION',
            ],
            ops: [
                // this.OPS.C,
                this.OPS.R,
                this.OPS.U,
                // this.OPS.D,
            ],
            scope: [
                this.SCOPE.SAME_STATION
            ]
        },
        {
            features: [
                this.features.DASHBOARD,
                this.features.SCHEDULER,
                this.features.SCHEDULER_PAST,
                this.features.SCHEDULER_FUTURE,
                this.features.COMPANY_MANAGEMENT,
                this.features.CM_STATIONS,
                this.features.ST_SHIFT_TYPES,
                this.features.ST_SCHEDULES,
                this.features.SCHEDULE_BALANCE,
                this.features.EMPLOYEE_BALANCE
            ],
            roles: [
                'ROLE_LEAD_DRIVER'
            ],
            ops: [
                this.OPS.C,
                this.OPS.R,
                this.OPS.U,
                this.OPS.D,
            ],
            scope: [
                this.SCOPE.SAME_STATION
            ]
        },
        {
            features: [
                this.features.CM_SKILL,
                this.features.CM_EMPLOYEE
            ],
            roles: [
                'ROLE_LEAD_DRIVER'
            ],
            ops: [
                this.OPS.R
            ],
            scope: [
                this.SCOPE.SAME_STATION
            ]
        },
        {
            features: [
                this.features.SCHEDULER_PAST,
                this.features.SCHEDULER_FUTURE,
                this.features.ST_SCHEDULES,
            ],
            roles: [
                'ROLE_DISPATCHER_DAILY',
            ],
            ops: [
                this.OPS.R,
            ],
            scope: [
                this.SCOPE.SAME_STATION
            ]
        },
        {
            features: [
                this.features.VAN_REPORT,
            ],
            roles: [
                'ROLE_STATION_MANAGER',
                'ROLE_ASSISTANT_STATION_MANAGER',
                'ROLE_FLEET_MANAGER',
            ],
            ops: [
                this.OPS.R,
                this.OPS.U,
            ],
            scope: [
                this.SCOPE.SAME_STATION
            ]
        },
        {
            features: [
                this.addons.VEHICLE_MAINTENANCE_PLAN,
            ],
            roles: [
                'ROLE_OWNER',
            ],
            ops: [
                // this.OPS.C,
                this.OPS.R,
                this.OPS.U,
                // this.OPS.D,
            ],
            scope: [
                this.SCOPE.COMPANY
            ]
        },
        {
            features: [
                this.features.SYS_ADMIN,
            ],
            roles: [
                'ROLE_OPERATIONS_ACCOUNT_MANAGER',
                'ROLE_CENTRAL_DISPATCHER',
                'ROLE_DISPATCHER_WITH_NO_OPEN_SHIFT_FEATURE'
            ],
            ops: [],
            scope: [
                this.SCOPE.COMPANY
            ]
        },
        {
            features: [
                this.features.STATION_NARRATIVE,
            ],
            roles: [
                'ROLE_OWNER',
                'ROLE_OPERATIONS_ACCOUNT_MANAGER',
                'ROLE_OPERATIONS_MANAGER',
                'ROLE_CENTRAL_DISPATCHER',
                'ROLE_STATION_MANAGER',
                'ROLE_ASSISTANT_STATION_MANAGER',
                'ROLE_DISPATCHER',
                'ROLE_DISPATCHER_WITH_NO_OPEN_SHIFT_FEATURE',
                'ROLE_LEAD_DRIVER',
                'ROLE_HR_ADMINISTRATOR',
                'ROLE_SAFETY_MANAGER',
                'ROLE_FLEET_MANAGER',
            ],
            ops: [
                this.OPS.R,
                this.OPS.C,
                this.OPS.U,
                this.OPS.D,
            ],
            scope: [
                this.SCOPE.SAME_STATION
            ]
        }
    ]

    static #cache = {
        plans: {},
        roles: {}
    };

    static computePermissions(prop, value) {
        // get features form token
        let features = [];
        try {
            features = features = Array.isArray(parseToken()?.features) ? parseToken().features : [];
        } catch (e) {}

        return this.#config.reduce((all, next) => {
            const c = next[prop];

            if (!c || !c.includes(value))
                return all;

            next.features = [
                ...features,
                ...next.features,
            ]

            all = {
                ...all,
                ...Object.fromEntries(
                    next.features.map(f => ([
                        f,
                        {
                            ops: next?.ops || [],
                            scope: next?.scope || []
                        }
                    ]))
                )
            }

            return all;
        }, {});
    }

    static isGranted({ plan, role, feature, ops, station }) {
        if (![...Object.values(this.features), ...Object.values(this.addons)].includes(feature))
            throw new Error("Unknown feature");

        if (plan) {
            if (!this.#cache.plans[plan])
                this.#cache.plans[plan] = this.computePermissions('plans', plan);

            if (!this.#cache.plans[plan][feature])
                return false;
        }

        if (role) {
            if (!this.#cache.roles[role])
                this.#cache.roles[role] = this.computePermissions('roles', role);

            let access = this.#cache.roles[role][feature];

            if (!access)
                return false;

            ops = ops || [
                this.OPS.R
            ];

            let o = !ops || access.ops.filter(o => ops.includes(o)).length > 0;
            let s = !access.scope.includes(AccessCheck.SCOPE.SAME_STATION) || !station || getMainStation().id === station;

            return o && s;
        }

        return true;
    }
}

export default AccessCheck;

window.__AC = AccessCheck;