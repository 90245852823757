import React, {useEffect, useState} from 'react';
import {Button, Icon, Spacer, SubTitle, TagInput, Theme, ThirdTitle} from '@dspworkplace/ui';
import {Back, Body, Content, Menu, Navigator} from "../../../components/Menus/Navigator";
import {Link, Status} from "../../../components/UI";
import {useForm} from "react-hook-form";
import {engine} from "../../../Utilities";
import {Table, TableData, TableFooter, TableHeader, TableRow} from "../../../components/Table";
import Loading, {LoadingWrapper} from "../../../components/Loading";
import Empty from "../../../components/Empty";
import {formatCurrency, formatDate} from "../../Settings/Sections/subscriptionInvoices";

const api = engine({hideDefaultMessage: true});

const Filters = ({
    setLoading,
    setFilters,
    setData,
}) => {
    const [fetching, setFetching] = useState(true);
    const [products, setProducts] = useState([]);
    const {register, handleSubmit} = useForm();

    useEffect(() => {
        api.post('/api/lazy/manage/data', {
            actions: {
                response: {
                    "Subscription\\SubscriptionProduct": {
                        findAll: {
                            get: "products"
                        }
                    }
                }
            }
        }).then(response => {
            setFetching(false);
            setProducts(response.data.data.products)
        })
    }, []);

    const onSubmit = (values) => {
        setFilters({
            product: products.find(p => p.id === values['product']),
            // startDate: moment(values['start-date']),
            // endDate: moment(values['end-date']),
        })
        setLoading(true);
        api.get(`/api/stripe/revenue-report?product=${values['product']}`)
            .then(response => {
                setLoading(false);
                if (response.data.success)
                    setData(response.data.result);
            });
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{position: 'relative'}}>
            {fetching && <LoadingWrapper><Loading/></LoadingWrapper>}
            {/*
                <DatePicker
                    ref={register}
                    name='start-date'
                    label='Start Date'
                    size='small'
                    required
                    Required
                />
                <Spacer right={5} inline/>
                <DatePicker
                    ref={register}
                    name='end-date'
                    label='End Date'
                    size='small'
                    required
                    Required
                />
                <Spacer bottom={3}/>
                */}
            <TagInput
                ref={register}
                name='product'
                label='Product'
                canCreate={false}
                singleOption={true}
                options={products.map(p => ({
                    name: p.name,
                    value: p.id
                }))}
                required
                Required
            />
            <Spacer bottom={5}/>
            <Button
                type='primary'
            >Generate</Button>
        </form>
    );
}

const App = () => {
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({});
    const [data, setData] = useState({});

    const {total, priceTotal, rows, sortedBy} = data;

    const headers = [
        {
            key: 'invoiceStatus',
            label: 'Invoice Status',
            width: '140px',
            sortBy: 'invoiceStatus',
            formatDisplay(row) {
                return <>
                    <Status current={row.invoiceStatus !== 'paid' ? 'error' : 'success'}>
                        {row.invoiceStatus.toUpperCase()}
                    </Status>
                    <Spacer inline right={3}/>
                    <Link href={`https://dashboard.stripe.com/invoices/${row.invoiceProcessorId}`} target='_blank'>
                        <Icon.TrendUp
                            size={'16px'}
                            color={Theme.colors.info.border}
                        />
                    </Link>
                </>
            },
            formatExport(row) {
                return row.invoiceStatus.toUpperCase();
            }
        },
        {
            label: "Invoice Link",
            hidden: true,
            formatExport(row) {
                return `https://dashboard.stripe.com/invoices/${row.invoiceProcessorId}`;
            }
        },
        {
            key: 'companyName',
            label: 'Company',
            width: '340px',
            sortBy: 'companyName',
            formatDisplay(row) {
                return <>
                    <Status current={row.subscriptionStatus !== 'active' ? 'error' : 'success'}>
                        {row.subscriptionStatus.toUpperCase()}
                    </Status>
                    <Spacer inline right={3}/>
                    {row.companyName}
                    <Spacer inline right={3}/>
                    <Link href={`https://dashboard.stripe.com/subscriptions/${row.subscriptionProcessorId}`} target='_blank'>
                        <Icon.TrendUp
                            size={'16px'}
                            color={Theme.colors.info.border}
                        />
                    </Link>
                </>;
            }
        },
        {
            label: "Subscription Link",
            hidden: true,
            formatExport(row) {
                return `https://dashboard.stripe.com/subscriptions/${row.subscriptionProcessorId}`;
            }
        },
        {
            label: "Subscription Status",
            hidden: true,
            formatExport(row) {
                return row.subscriptionStatus.toUpperCase();
            }
        },
        {
            key: 'companyUsage',
            label: 'Usage',
            width: '140px',
            sortBy: 'companyUsage',
        },
        {
            key: 'totalPrice',
            label: 'Total Price',
            width: '140px',
            sortBy: 'totalPrice',
            formatDisplay(row) {
                return formatCurrency(row.totalPrice);
            },
            formatExport(row) {
                return formatCurrency(row.totalPrice);
            }
        },
        {
            key: 'invoiceDate',
            label: 'Invoice Date',
            width: '180px',
            formatDisplay(row) {
                return formatDate(row.invoiceDate.toString() + '000');
            },
            formatExport(row) {
                return formatDate(row.invoiceDate.toString() + '000');
            }
        },
        {
            key: 'invoicePaidDate',
            label: 'Payment Date',
            width: '180px',
            formatDisplay(row) {
                return row.invoicePaidDate
                    ? formatDate(row.invoicePaidDate.toString() + '000')
                    : '–'
            },
            formatExport(row) {
                return row.invoicePaidDate
                    ? formatDate(row.invoicePaidDate.toString() + '000')
                    : '–'
            }
        },
    ];

    const exportAsCsv = () => {
        let csvContent = "data:text/csv;charset=utf-8,"
            + headers.map(h => h.label).join(",") + "\n"
            + rows.map(row => headers.map(h => JSON.stringify(h.formatExport ? h.formatExport(row) : row[h.key])).join(",")).join("\n");
        window.open(encodeURI(csvContent));
    }

    return (
        <Body>
            <Navigator style={{width: 341}}>
                <Back>
                    <Link to='/company-management/custom-reports'>
                        <Icon.ArrowLeft size='16px'/>
                        <span>Back to Custom Reports</span>
                    </Link>
                </Back>
                <SubTitle>Revenue Report</SubTitle>
                <Spacer top={3} style={{borderBottom: '1px solid ' + Theme.colors.info.bg}}/>
                <Spacer top={4}/>
                <Menu>
                    <Filters
                        setLoading={setLoading}
                        setFilters={setFilters}
                        setData={setData}
                    />
                </Menu>
            </Navigator>
            <Content style={{position: 'relative'}}>
                {loading && <LoadingWrapper><Loading/></LoadingWrapper>}
                <Spacer bottom={5} style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Spacer>
                        {filters.product && filters.startDate && filters.endDate && (
                            <SubTitle>Revenue Report for {filters.product?.name} between {filters.startDate} and {filters.endDate}</SubTitle>
                        )}
                    </Spacer>
                    <Button
                        type='default'
                        size='small'
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}
                        onClick={exportAsCsv}
                    >
                        <span>Export to CSV</span>
                        <Spacer inline right={2}/>
                        <Icon.Download size={'16px'}/>
                    </Button>
                </Spacer>
                <Table>
                    <TableHeader
                        headers={headers.filter(h => !h.hidden)}
                        sortedBy={sortedBy}
                        sortByFunc={(field) => {
                            const sorted = (rows || []).sort((f, s) => {
                                return f[field].toString().localeCompare(s[field].toString(), undefined, {numeric: true});
                            });

                            if (!sortedBy[0].direction)
                                sorted.reverse();

                            setData({
                                rows: sorted,
                                sortedBy: [
                                    {
                                        field,
                                        direction: +!sortedBy[0].direction,
                                    }
                                ]
                            });
                        }}
                    />
                    {(rows || []).length === 0 && <Empty/>}
                    {(rows || []).map((row, index) => (
                        <TableRow key={row.companyId} style={{background: row.invoiceStatus !== 'paid' ? Theme.colors.error.bg : null }}>
                            {headers.filter(h => !h.hidden).map(h => (
                                <TableData width={h.width} key={h.key}>
                                    {h.formatDisplay ? h.formatDisplay(row) : row[h.key]}
                                </TableData>
                            ))}
                        </TableRow>
                    ))}
                    <TableFooter sticky>
                        <Spacer right={120}/>
                        <ThirdTitle style={{width: 140}}>Total: {total ?? '–'}</ThirdTitle>
                        <ThirdTitle style={{flexGrow: 1}}>Revenue: {priceTotal ? formatCurrency(priceTotal) : '–'}</ThirdTitle>
                    </TableFooter>
                </Table>
            </Content>
        </Body>
    );
};

export default App;
