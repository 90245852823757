import React, { createContext, useReducer } from "react";
import VehicleImageReduce from "./reducer";

const Store = createContext({});

const initialState = {
    vehicleId: "",
    sortBy: [{ direction: 1, field: "createdAt" }],
    vehicleImages: [],
    vehicleImageCategory: [],
    filter: { category: "all", search: "" },
    isLoading: true,
    isLoadingWrapper: false,
    list: [],
    tempList: [],
    page: 1,
    totalPages: 0,
    vehiclesOpts: [],
    driversOpts: [],
    selectedVehicle: '',
    selectedDriver: '',
    selectedImageArr: [],
    selectedCategory:'',
    label:'',
    selectedTimeframe: false,
    selectedItem: null,
};
const VehicleImageProvider2 = ({ children }) => {
    const useWeekState = useReducer(VehicleImageReduce, initialState);
    return <Store.Provider value={useWeekState}>{children}</Store.Provider>;
};

export { VehicleImageProvider2 };

export default Store;
