import React, { useEffect, useContext, useState, useCallback, useMemo } from "react";
import styled from "styled-components";
import { Theme, Icon, Spacer, Text, Title, CheckBox, Button } from "@dspworkplace/ui";
import { Badge, Label, Link } from "../../../components/UI";
import { useAuth, getCompanyId } from "../../../Auth";
import { confirm } from "../../../components/Confirm";
import { dialogPromise } from "../../../components/Dialog";
import Empty from "../../../components/Empty";
import { useParams } from "react-router-dom";
import { fetchVehicleImage, deleteVehicleImage, fetchVehicleImageCategory, fetchVehicleDetail, handlePrintImageVehicle } from "./api";
import Store, { VehicleImageProvider2 } from "./Context";
import Filter from "./Filter";
import Modal, { useModal } from "../../../components/Modal";
import moment from "moment";
import Skeleton from "../../../components/Skeleton";
import { showVehicleImageEditForm } from "./editForm";
import ReactImageMagnify from "react-image-magnify";
import Loading, { LoadingWrapper } from "../../../components/Loading";

import { useIntersection } from "../../../hooks/useIntersect";

import StationSelector, { useSelectedStation } from "../../../components/StationSelector";
import { Btn } from "../../../components/Grid/Tools/Filter/Filter";
import { showVehicleImageForm } from "./form";
import Tooltip from "../../../components/Tooltip/tooltip";

const Wrapper = styled.div`
    min-height: 100%;
    //max-width: 850px;
    width: 100%;
`;

const TagArea = styled.div`
    padding-left: 24px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    margin-bottom: 45px;
`;

const InfosArea = styled.div`
    position: absolute;
    bottom: 8px;
`;

const Card = styled.div`
    display: block;
    box-sizing: border-box;
    background: ${Theme.colors.neutrals.white};
    box-shadow: 0 3px 6px #33333326;
    width: 210px;
    height: 100%;
    border-radius: ${Theme.defaultRadius};
    position: relative;

    .icon-action {
        &:hover {
            > path {
                fill: ${Theme.colors.info.text};
            }
        }
    }
`;

const TitleCard = styled.div`
    padding-left: 24px;
    display: inline-block;
`;

const Wrap = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 210px));
    grid-column-gap: 14px;
    grid-row-gap: 14px;
    padding-bottom: 30px;
`;

const SpacerInline = styled(Spacer)`
    display: inline-flex;
    align-items: center;
`;

const Image = ({src}) => {
    const [error, setError] = useState(null);

    const style = {
        width: 175,
        height: 160
    };

    return error ? (
        <div style={{...style, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: Theme.colors.extra.gray93}}>
            <Empty/>
        </div>
    ) : (
        <img
            style={style}
            src={src}
            onError={() => setError(true)}
        />
    )
}

const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
        cursor: pointer;
    }
`;

const Tag = styled.div`
    box-sizing: border-box;
    min-width: 36px;
    height: 28px;
    text-align: center;
    margin-bottom: 2px;
    padding: 2px 4px 2px 4px;
    background: ${Theme.colors.info.bg} 0% 0% no-repeat padding-box;
    border-radius: ${Theme.defaultRadius};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: ${Theme.colors.info.text};
    border: 1px solid ${Theme.colors.info.border};
`;

const LWrap = styled(LoadingWrapper)`
    height: 100%;
`;


const App = () => {
    return (
        <VehicleImageProvider2>
            <Wrapper>
                <Spacer top={5} />
                <Header />
                <Spacer top={5} />

                <VehicleImageList />
            </Wrapper>
        </VehicleImageProvider2>
    );
};

const Header = (props) => {
    const { api } = useAuth();
    const [selectedStation] = useSelectedStation();
    const [state, dispatch] = useContext(Store);
    const [open, setOpen] = useState(false);
    const [val, setVal] = useState({});

    // const VehicleId = state.selectedVehicle.name;
    const [badge, setBadge] = useState(0);
    const vehicleId = state?.vehicleId;
    const driverValue = state?.driversOpts.find(driver => driver?.name === state.selectedItem?.friendlyName)?.value;

    const handleAdd = () => {
        showVehicleImageForm({ api: api, vehicle: vehicleId, categories: state.vehicleImageCategory }).then(
            async (vehicleImage) => {
                const limit = window.screen.width < 1201 ? 6 : 16;
                const result = await fetchVehicleImage(api, vehicleId, 1, limit, state, '', '', selectedStation, '', '');
                if (result) {
                    dispatch({ type: "ADD", payload: { data: result.data.vehicleImages.list, page: result.data.vehicleImages.page, totalPages: result.data.vehicleImages.totalPages } });
                }
            },
            (cancel) => () => { }
        );
    }

    const handlePrint = async () => {
        dispatch({ type: "LOADING_WRAPPER", payload: { status: true } });
        dispatch({ type: "RESET_SELECTED_IMAGE", payload: {} });
        const result = await handlePrintImageVehicle(api, state.selectedImageArr, state.selectedItem.vehicleId, driverValue);
        dispatch({ type: "LOADING_WRAPPER", payload: { status: false } });
        let element = document.createElement("a");
        element.setAttribute("href", result.data.handlePrintImageVehicle);
        element.setAttribute(
            "download",
            result.data.handlePrintImageVehicle
        );
        element.setAttribute("target", "_blank");
        // target='_blank'
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    const TooltipContent = ({ content }) => {
        return <div>{content}</div>
    }

    const ButtonRender = ({ children, content }) => {
        if (!content || content.length < 1) return children
        return <Tooltip direction='bottom-start' content={<TooltipContent content={content} />}>{children}</Tooltip>
    }

    const TooltipRender = ({ label, value }) => {
        return <p>{label} = {value}</p>
    }


    const appliedFilters = useMemo(() => {
        const filters = []

        Object.keys(val).forEach(filterKey => {
            const value = filterKey == "vehicleId" ? state?.vehiclesOpts?.filter((items) => items.value == val[filterKey])?.[0]?.name : filterKey == "categoryId" ? state?.categoryOpts?.filter((items) => items.value == val[filterKey])?.[0]?.name : filterKey == "driversId" ? state?.driversOpts?.filter((items) => items.value == val[filterKey])?.[0]?.name : val[filterKey].hasOwnProperty('value') ? val[filterKey].value : val[filterKey];

            // @ts-ignore            
            if (value !== null&&value && value != '' && value != "all" && value != "All") filters.push(<TooltipRender label={filterKey} value={value} key={filterKey} />);
        })
        return filters
    }, [val])




    return (
        <>
            <Label
                as={Link}
                to={"/company-management/vehicles/vehicle-management"}
                style={{ color: Theme.colors.info.border, cursor: "pointer", textDecoration: "none" }}
            >
                <Icon.ArrowLeft
                    size={"16px"}
                    color={Theme.colors.info.border}
                    style={{ verticalAlign: "middle", marginRight: 4 }}
                />
                <small>Back to Vehicle Management</small>
            </Label>
            <Spacer top={5} />
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                <Title>
                    Vehicles Images Viewer
                    {state.vehicleId&&state.vehicleId!="all" && "(" + state?.vehiclesOpts?.filter((item) => item?.value == (state.vehicleId?.id ||state.vehicleId) )[0]?.name + ")"}
                </Title>

                <div style={{ display: "flex", justifyContent: "end", alignItems: "end",marginRight:"20px" }}>

                    <Filter setVal={setVal} setBadge={setBadge} open={open} setOpen={setOpen} />
                    <Spacer inline right={3} />

                    <Spacer inline right={3} />
                    <ButtonRender content={appliedFilters}>
                        <Btn onClick={() => setOpen(true)}>
                            <span>Filter</span>
                            <Icon.Filter />
                            {badge > 0 && <Badge>{badge}</Badge>}
                        </Btn>
                    </ButtonRender>
                </div>
            </div>
            <Spacer top={3} />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {vehicleId && vehicleId != 'all' &&
                    <Button type="primary" onClick={handleAdd} style={{ alignSelf: "flex-end", marginRight: "20px" }}>
                        Add Image
                    </Button>}
                {state.selectedImageArr?.length > 0 &&
                    <>
                        <Button type="primary" onClick={handlePrint} style={{ alignSelf: "flex-end", marginRight: "20px" }}>
                            Print to PDF
                        </Button>
                    </>
                }
            </div>
        </>
    );
};

const VehicleImageList = () => {
    const [state, dispatch] = useContext(Store);
    const [load, setLoad] = useState(false);

    const { api } = useAuth();

    let { id } = useParams();

    if (id === ":id" || id === "id") id = false;

    if (state.selectedVehicle.value) id = state.selectedVehicle.value;

    const driverId = state.selectedDriver;
    const vehicleId = state.vehicleId || "";
    const categoryId = state.selectedCategory;
    const timeframe = state.selectedTimeframe;
    const label = state.label;
    const [station] = useSelectedStation();

    const DeleteRole = [
        "ROLE_OWNER",
        "ROLE_STATION_MANAGER",
        "ROLE_OPERATIONS_MANAGER",
        "ROLE_OPERATIONS_ACCOUNT_MANAGER",
        "ROLE_FLEET_MANAGER",
    ];

    const loadList = async (page = 1) => {
        const limit = window.screen.width < 1201 ? 6 : 24;

        const result = await fetchVehicleImage(api, vehicleId, page, limit, state, driverId, timeframe, station, categoryId, label);
        if (result) {
            dispatch({
                type: "ADD",
                payload: { data: result.data.vehicleImages.list, page, totalPages: result.data.vehicleImages.totalPages },
                // payload: { data: result.data.vehicleImages, page, totalPages: result.pagination.totalPages },
            });
            setLoad(true);
        } else {
            dispatch({
                type: "ADD",
                payload: { data: [], page, totalPages: 0 },
            });
        }
    };

    useEffect(() => {
        state.page = 1;

        setLoad(false);
        loadList(1);
    }, [id, driverId, timeframe, station, categoryId, label, vehicleId]);

    const LoaderRef = React.useRef(null);
    const entry = useIntersection(LoaderRef, {
        threshold: 0.05,
    });
    const isLoadingVisible = !!entry?.isIntersecting;

    useEffect(() => {
        if (isLoadingVisible && state.page < state.totalPages && load) {
            loadList(state.page + 1);
        }
    }, [isLoadingVisible]);

    const fetchAll = async () => {
        if (!state.isLoading) dispatch({ type: "LOADING", payload: { status: true } });
        const vehicleId = await fetchVehicleDetail(api, id);
        dispatch({ type: "ADD_VEHICLEID", payload: vehicleId });

        // const result = await fetchVehicleImage(api, id, { page: 1 });
        // dispatch({ type: "ADD", payload: { data: result } });
    };

    const handleDeletion = async (id) => {
        let confirmation = await confirm(`Confirm you want to delete Vehicle Image ?`);
        if (confirmation) {
            await deleteVehicleImage(api, id);
            dispatch({ type: "REMOVE", payload: { id: id } });
        }
    };

    const handleEdit = (data, key) => {
        showVehicleImageEditForm({ api: api, vehicleImage: data, categories: state.vehicleImageCategory }).then(
            async (vehicleImage) => {
                dispatch({ type: "EDIT", payload: { vehicleImage: vehicleImage, key: key } });
            },
            (cancel) => () => {}
        );
    };

    const fetchCategory = async () => {
        const result = await fetchVehicleImageCategory(api);
        dispatch({ type: "ADD_CATEGORY", payload: result });
    };

    useEffect(() => {
        if(id){ 
            Promise.all([fetchAll()]).then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        fetchCategory();
    }, []);

    const openImagePopup = (image) => {
        return dialogPromise((success, cancel) => <ImageModal success={success} cancel={cancel} image={image} />);
    };

    const shouldShowCheckbox = (data) => {
        if (!state.selectedItem) return true;

        return data.vehicle?.vehicleId === state.selectedItem.vehicleId &&
            data.updatedBy?.friendlyName === state.selectedItem.friendlyName;
    };

    const handleCheckboxClick = (data) => {
        const isSelected = state.selectedImageArr.includes(data.id);

        if (isSelected) {
            const newSelectedImageArr = state.selectedImageArr.filter(id => id !== data.id);
            dispatch({ type: 'SET_SELECTED_IMAGE', payload: newSelectedImageArr });

            if (newSelectedImageArr.length === 0) {
                dispatch({ type: 'SET_SELECTED_ITEM', payload: null });
            }
        } else {
            dispatch({ type: 'SET_SELECTED_IMAGE', payload: [...state.selectedImageArr, data.id] });

            dispatch({
                type: 'SET_SELECTED_ITEM',
                payload: {
                    vehicleId: data.vehicle?.vehicleId,
                    friendlyName: data.updatedBy?.friendlyName
                }
            });
        }
    };

    return (
        <>
            {state.isLoadingWrapper && (
                <LWrap id="week-table-loading" style={{ position: "fixed" }}>
                    <Loading style={{ width: 40, height: '100%', display: "block", position: "fixed" }} />
                </LWrap>
            )}

            <Wrap>
                {state.isLoading ? (
                    <>
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                    </>
                ) : state.list.length > 0 ? (
                    state.list.map((data, key) => {
                        return (
                            <Card key={JSON.stringify({ key, n: Math.random() })}>
                                <Spacer all={5}>
                                    <Actions>
                                        <Label />
                                        <div style={{ position: "relative", top: "2px" }}>
                                            <SpacerInline>
                                                {shouldShowCheckbox(data) && (
                                                    <SpacerInline right={9}>
                                                        <CheckBox
                                                            name={data.id}
                                                            options={[{ value: data.id, label: "" }]}
                                                            onClick={() => {
                                                                handleCheckboxClick(data)
                                                            }}
                                                            defaultValues={state.selectedImageArr}
                                                        />
                                                    </SpacerInline>
                                                )}
                                                <SpacerInline right={3}>
                                                    <div
                                                        onClick={async () => {
                                                            // href={data.path} target={'_blank'} download={''}
                                                            // const response = await fetch(data.path, { mode: 'cors' }); // Include mode: 'cors' for CORS handling
                                                            // if (!response.ok) {
                                                            // throw new Error(`Error downloading image: ${response.statusText}`);
                                                            // }
                                                            // const blob = await response.blob();
                                                            // const link = document.createElement('a');
                                                            // link.href = URL.createObjectURL(blob);
                                                            // link.download = 'downloaded_image.jpg'; // Set desired filename
                                                            // link.click();

                                                            // try {
                                                            //     const image = await fetch(data.path);
                                                            //     const imageBlog = await image.blob();
                                                            //     const imageURL = URL.createObjectURL(imageBlog);
                                                            // } catch (e) {}

                                                            // const link = document.createElement('a')
                                                            // link.href = imageURL;
                                                            // link.download = data.path.replace("https://s3.amazonaws.com/dsp.data.storage.general/", "");
                                                            // document.body.appendChild(link)
                                                            // link.click()
                                                            // document.body.removeChild(link)
                                                            // let element = document.createElement("a");
                                                            // element.setAttribute("href", data.path);
                                                            // element.setAttribute(
                                                            //     "download",
                                                            //     data.path.replace(
                                                            //         "https://s3.amazonaws.com/dsp.data.storage.general/",
                                                            //         ""
                                                            //     )
                                                            // );
                                                            // element.setAttribute("target", "_blank");
                                                            // // target='_blank'
                                                            // document.body.appendChild(element);
                                                            // element.click();
                                                            // document.body.removeChild(element);


                                                            try {
                                                                const response = await fetch(data.path + "?not-from-cache-please");
                                                                const blob = await response.blob();
                                                                const url = window.URL.createObjectURL(blob);
                    
                                                                let element = document.createElement("a");
                                                                element.setAttribute("href", url);
                                                                element.setAttribute(
                                                                    "download",
                                                                    data.path.replace(
                                                                        "https://s3.amazonaws.com/dsp.data.storage.general/",
                                                                        ""
                                                                    )
                                                                );
                                                                element.setAttribute("target", "_blank");
                                                                document.body.appendChild(element);
                                                                element.click();
                                                                document.body.removeChild(element);
                                                            } catch (error) {
                                                                console.error("There was a problem with the fetch operation:",error);
                                                            }
                                                        }}
                                                    >
                                                        <Icon.Download
                                                            size="18px"
                                                            color={Theme.colors.info.border}
                                                            className="icon-action"
                                                        />
                                                    </div>
                                                </SpacerInline>
                                                <SpacerInline right={3}>
                                                    <div onClick={() => handleEdit(data, key)}>
                                                        <Icon.Edit
                                                            size="18px"
                                                            color={Theme.colors.info.border}
                                                            className="icon-action"
                                                        />
                                                    </div>
                                                </SpacerInline>
                                                {DeleteRole.indexOf(localStorage.getItem("currentUserRole")) > -1 && (
                                                    <SpacerInline right={3}>
                                                        <div onClick={() => handleDeletion(data.id)}>
                                                            <Icon.Times
                                                                size="18px"
                                                                color={Theme.colors.info.border}
                                                                className="icon-action"
                                                            />
                                                        </div>
                                                    </SpacerInline>
                                                )}
                                            </SpacerInline>
                                        </div>
                                    </Actions>
                                    <Spacer top={3} />
                                    {data.inspectionImagePath && data.inspectionImagePath != "" ? (
                                        <div
                                            onClick={() => {
                                                openImagePopup(data.inspectionImagePath).then();
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <Image src={data.inspectionImagePath} />
                                        </div>
                                    ) : (
                                        <div
                                            onClick={() => {
                                                openImagePopup(data.path).then();
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <Image src={data.path} />
                                        </div>
                                    )}
                                    <Spacer top={3} />
                                    <Spacer right={5} style={{ position: "relative", marginBottom: 4 }}>
                                        <Icon.Notes
                                            size="16px"
                                            color={Theme.colors.info.border}
                                            style={{ verticalAlign: "middle", position: "absolute" }}
                                        />
                                        <TitleCard>
                                            <Text>
                                                {typeof data.label === "string" && data.label != ""
                                                    ? data.label.trim()
                                                    : "-"}
                                            </Text>
                                        </TitleCard>
                                    </Spacer>
                                    {data.inspectionImagePath && data.inspectionImagePath != "" && (
                                        <Spacer right={5} style={{ position: "relative" }}>
                                            <Icon.Info
                                                size="16px"
                                                color={Theme.colors.info.border}
                                                style={{ verticalAlign: "top", position: "absolute" }}
                                            />
                                            <TitleCard>
                                                {data.inspectionFailedNote != '' && data.inspectionFailedNote != null && <Text>{data.inspectionFailedNote.charAt(0).toUpperCase() + data.inspectionFailedNote.slice(1)}</Text>}
                                                <Link title={'Original Pic'} onClick={() => { openImagePopup(data.path).then(); }}>(Original Pic)</Link>
                                            </TitleCard>
                                        </Spacer>
                                    )}
                                    <Spacer right={5} style={{ position: "relative" }}>
                                        <Icon.Skill
                                            size="16px"
                                            color={Theme.colors.info.border}
                                            style={{ verticalAlign: "top", position: "absolute" }}
                                        />
                                        <TagArea>
                                            {data.category && data.category.length > 0
                                                ? data.category.map((c, i) => <Tag key={i}>{c.name}</Tag>)
                                                : "-"}
                                        </TagArea>
                                    </Spacer>
                                    <InfosArea>
                                        <Spacer right={5}>
                                            <Icon.Info
                                                size="16px"
                                                color={Theme.colors.info.border}
                                                style={{ verticalAlign: "middle" }}
                                            />
                                            <Spacer inline left={2}>
                                                <Text>{data.vehicle && data.vehicle.vehicleId}</Text>
                                            </Spacer>
                                        </Spacer>
                                        <Spacer right={5}>
                                            <Icon.Clock
                                                size="16px"
                                                color={Theme.colors.info.border}
                                                style={{ verticalAlign: "middle" }}
                                            />
                                            <Spacer inline left={2}>
                                                <Text>
                                                    {data.createdAt
                                                        ? moment
                                                              .utc(data.createdAt.timestamp * 1000)
                                                              .local()
                                                              .format("MMM D, Y")
                                                        : "-"}
                                                </Text>
                                            </Spacer>
                                        </Spacer>
                                        <Spacer right={5}>
                                            <Icon.Info
                                                size="16px"
                                                color={Theme.colors.info.border}
                                                style={{ verticalAlign: "middle" }}
                                            />
                                            <Spacer inline left={2}>
                                                <Text>{data.updatedBy && data.updatedBy.friendlyName}</Text>
                                            </Spacer>
                                        </Spacer>
                                    </InfosArea>
                                </Spacer>
                            </Card>
                        );
                    })
                ) : (
                    state.isLoading === false && (
                        <Spacer inline left={100}>
                            <Empty />
                        </Spacer>
                    )
                )}
            </Wrap>

            <div
                style={{ marginBottom: 30, visibility: state.page != state.totalPages ? "visible" : "hidden" }}
                ref={LoaderRef}
            >
                {state.list.length > 0 && (
                    <Loading style={{ width: "40px", margin: "30px auto", display: "block", paddingBottom: 30 }} />
                )}
            </div>
        </>
    );
};

const ImageModal = ({ success, cancel, image }) => {
    const [loading, setLoading] = useState(false);
    return (
        <Modal title={"Image"} visible={true} width="700px" setVisible={cancel} disabled={loading}>
            <ReactImageMagnify
                {...{
                    smallImage: { isFluidWidth: true, src: image },
                    largeImage: { src: image, width: 900, height: 700 },
                    enlargedImageContainerStyle: { background: "#fff", zIndex: 9, enlargedImagePosition: "over" },
                }}
            />
            {/*<img style={{maxWidth:'660px'}} src={image}/>*/}
        </Modal>
    );
};

const SkeletonCard = () => {
    return (
        <Card style={{ position: "relative" }}>
            <Spacer all={5}>
                <div style={{ height: "20px" }}>
                    <Skeleton style={{ width: "81%", float: "left" }} />
                    <Skeleton style={{ width: "15%", float: "right" }} />
                </div>
                <Spacer top={3} />

                <div style={{ height: "140px" }}>
                    <Skeleton style={{ width: "100%", float: "left" }} />
                </div>
                <Spacer top={3} />
                <div style={{ height: "20px" }}>
                    <Skeleton style={{ width: "15%", float: "left" }} />
                    <Skeleton style={{ width: "81%", float: "right" }} />
                </div>
                <Spacer top={3} />
                <div style={{ height: "20px" }}>
                    <Skeleton style={{ width: "15%", float: "left" }} />
                    <Skeleton style={{ width: "81%", float: "right" }} />
                </div>
            </Spacer>
        </Card>
    );
};

export default App;
